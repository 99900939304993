import { Editor } from "@tinymce/tinymce-react";
import React, { useState } from "react";

import LoadingOverlay from "react-loading-overlay";

import relayEnvironment from "../../../../RelayEnvironment";

import {
  ArticleEditorContainer,
  ArticleEditorWrapper,
  ArticleEditorHeader,
  ArticleEditorTitle,
  ArticleEditorSideBarContainer,
  ArticleEditorPageContainer,
  ArticleEditorSideBarImage,
  ArticleEditorSideBarImageUpload,
  ArticleEditorSideBarSportSelectLabel,
  ArticleEditorSideBarButtons,
} from "./styles";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import noImageUploaded from "../../../../assets/images/article_editor/no-image-uploaded.jpg";
import Button from "../../../Button";

import Select from "react-select";
import WarningBanner from "../../../WarningBanner";
import CreateArticleMutation from "./mutations/CreateArticleMutation";

const UPLOAD_ARTICLE_EMBED_IMAGE = gql`
  mutation UploadArticleEmbedImage($file: Upload!) {
    uploadArticleEmbedImage(file: $file) {
      imageUrl
    }
  }
`;

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $articleId: ID!
    $title: String!
    $content: String!
    $published: Boolean
    $categories: [CategoryInput!]!
    $playerMentions: [ID]
    $slateId: ID
  ) {
    updateArticle(
      id: $articleId
      title: $title
      content: $content
      published: $published
      categories: $categories
      playerMentions: $playerMentions
      slateId: $slateId
    ) {
      article {
        title
      }
      newSlug
    }
  }
`;

const GET_ARTICLE_BY_ID = gql`
  query GetArticleById($articleId: ID!) {
    articleById(articleId: $articleId) {
      id
      title
      content
      author {
        fullName
      }
      sport {
        id
        name
      }
      imageUrl
      categories {
        edges {
          node {
            id
            name
          }
        }
      }

      playersForArticleSlate {
        id
        salary
        player {
          name
        }
      }

      slate {
        id
        gameType {
          sport {
            name
          }
          name
        }
        prettyStartTime
        suffix

        slatecompetitionSet {
          edges {
            node {
              competition {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ARTICLE_CATEGORIES = gql`
  {
    articleCategories {
      id
      name
    }
  }
`;

const GET_TEMPLATES = gql`
  {
    articleTemplates {
      title
      content
    }
  }
`;

const GET_ACTIVE_SLATES = gql`
  {
    activeSlates {
      id
      gameType {
        sport {
          name
        }
        name
      }
      prettyStartTime
      suffix

      slatecompetitionSet {
        edges {
          node {
            competition {
              name
            }
          }
        }
      }
    }
  }
`;

const PLAYERS_FOR_SLATE = gql`
  query PlayerForSlate($slateId: ID!) {
    playersForSlate(slateId: $slateId) {
      id
      salary
      player {
        name
      }
    }
  }
`;

function ArticleEditor() {
  const history = useHistory();

  const { articleId, articleSlug } = useParams();

  let defaultArticleContent = "Type article here.";
  let defaultArticleTitle = "Insert Title Here";

  if (articleId === undefined) {
    const localBackup = localStorage.getItem("newArticleContent");
    const localTitleBackup = localStorage.getItem("newArticleTitle");

    if (localBackup !== null) {
      defaultArticleContent = localBackup;
    }

    if (localTitleBackup !== null) {
      defaultArticleTitle = localTitleBackup;
    }
  }

  const [showTemplates, setShowTemplates] = useState(false);
  const [articleContent, setArticleContent] = useState("");
  const [articleImageUpload, setArticleImageUpload] = useState(undefined);
  const [articleImageB64, setArticleImageB64] = useState(undefined);
  const [editor, setEditor] = useState(undefined);

  const [articleCategories, setArticleCategories] = useState(undefined);
  const [articleSlate, setArticleSlate] = useState(null);
  const [playersForArticleSlate, setPlayersForArticleSlate] = useState(
    undefined
  );
  const [loadingSlatePlayers, setLoadingSlatePlayers] = useState(false);

  const [submittingArticle, setSubmittingArticle] = useState(false);

  const [articleError, setArticleError] = useState(undefined);

  const titleEditable = React.useRef(null);

  const [getSlatePlayers] = useLazyQuery(PLAYERS_FOR_SLATE, {
    onCompleted: (data) => {
      setPlayersForArticleSlate(data.playersForSlate);
      setLoadingSlatePlayers(false);
    },
    onError: (error) => {
      setLoadingSlatePlayers(false);
      setArticleError(error);
    },
  });

  function reloadEditor() {
    // // eslint-disable-next-line no-undef
    // tinymce.EditorManager.execCommand(
    //   "mceRemoveEditor",
    //   true,
    //   "content-editor"
    // );
    // // eslint-disable-next-line no-undef
    // tinymce.EditorManager.execCommand("mceAddEditor", true, "content-editor");

    if (editor) {
      // eslint-disable-next-line no-undef
      tinymce.EditorManager.execCommand(
        "mceRemoveEditor",
        true,
        "content-editor"
      );
      // eslint-disable-next-line no-undef
      tinymce.EditorManager.execCommand("mceAddEditor", true, "content-editor");
    }
  }

  React.useEffect(() => {
    reloadEditor();
  }, [playersForArticleSlate]);

  const { loading, error, data } = useQuery(GET_ARTICLE_BY_ID, {
    variables: {
      articleId: articleId,
    },
    skip: articleId === undefined,
    onCompleted: (data) => {
      const categoriesList = data.articleById.categories.edges.map(
        (category) => {
          return { value: category.node.id, label: category.node.name };
        }
      );

      setArticleCategories(categoriesList);

      setPlayersForArticleSlate(data.articleById.playersForArticleSlate);

      if (data.articleById.slate) {
        setArticleSlate({
          value: data.articleById.slate.id,
          label: `${data.articleById.slate.gameType.sport.name} ${
            data.articleById.slate.slatecompetitionSet.edges[0].node.competition
              .name
              ? data.articleById.slate.slatecompetitionSet.edges[0].node
                  .competition.name
              : ""
          } ${data.articleById.slate.gameType.name} ${
            data.articleById.slate.suffix ? data.articleById.slate.suffix : ""
          } - ${data.articleById.slate.prettyStartTime}`,
        });
      } else {
        setArticleSlate(undefined);
      }
    },
  });
  const {
    loading: sportsLoading,
    error: sportsError,
    data: sportsData,
  } = useQuery(GET_ARTICLE_CATEGORIES);

  const { data: templatesData } = useQuery(GET_TEMPLATES);

  const {
    loading: slatesLoading,
    error: slatesError,
    data: slatesData,
  } = useQuery(GET_ACTIVE_SLATES);

  const [updateArticle] = useMutation(UPDATE_ARTICLE);
  const [uploadEmbedImage] = useMutation(UPLOAD_ARTICLE_EMBED_IMAGE);

  if (loading || sportsLoading || slatesLoading) return <></>;
  if (error || sportsError || slatesError) return <div>Error.</div>;

  const article =
    data !== undefined
      ? data.articleById
      : { title: defaultArticleTitle, content: defaultArticleContent };

  function handleEditorChange(content) {
    if (articleId === undefined) {
      localStorage.setItem("newArticleContent", content);
      localStorage.setItem("newArticleTitle", titleEditable.current.innerText);
    }
    setArticleContent(content);
  }

  function handleImageUpload(event) {
    event.preventDefault();

    const file = Array.from(event.target.files)[0];
    if (file !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (e) {
        const image = new Image();
        console.log(file);

        image.src = e.target.result;

        setArticleImageB64(file.name + ":" + reader.result.split(",")[1]);

        image.onload = function () {
          const width = this.width;

          if (width < 1200) {
            setArticleError("Article cover must be at least 1200 pixels wide.");
          } else {
            setArticleImageUpload(file);
            setArticleError(undefined);
          }
        };
      };
    }
  }

  function handleArticleEmbedImageUpload(blobInfo, success, failure, progress) {
    console.log(blobInfo);
    console.log(blobInfo.filename(), blobInfo.name(), blobInfo.uri());
    console.log(success, failure, progress);

    uploadEmbedImage({
      variables: {
        file: blobInfo.blob(),
        name: blobInfo.name(),
      },
    }).then((data) => {
      console.log(data);

      success(data.data.uploadArticleEmbedImage.imageUrl);
    });
  }

  function handleSportChange(selected) {
    setArticleCategories(selected);
  }

  function handleSlateChange(selected) {
    setArticleSlate(selected);

    const articleSlateId = selected.value;

    setLoadingSlatePlayers(true);

    getSlatePlayers({
      variables: {
        slateId: articleSlateId,
      },
    });
  }

  function parsePlayerMentionIds(content) {
    let playerMentions = content.match(
      /<button class="article-player-mention" data-player-id="[A-Za-z0-9+/]+"/g
    );

    if (!playerMentions) {
      return null;
    }

    return playerMentions.map((playerMention) =>
      playerMention.split("=").slice(-1)[0].replace(/"/g, "")
    );
  }

  function submitArticle(e, published, isTemplate = false) {
    e.preventDefault();
    console.log("Submitting..");
    setSubmittingArticle(true);

    if (articleId !== undefined) {
      const contentForSubmission =
        articleContent === "" ? article.content : articleContent;

      const playerMentionIds = parsePlayerMentionIds(contentForSubmission);

      updateArticle({
        variables: {
          articleId: articleId,
          title: titleEditable.current.innerText,
          content: contentForSubmission,
          published: published,
          categories: articleCategories,
          slateId: articleSlate ? articleSlate.value : null,
          playerMentions: playerMentionIds,
        },
      })
        .then(({ data }) => {
          setSubmittingArticle(false);
          history.push(
            "/article/" + articleId + "/" + data.updateArticle.newSlug
          );
        })
        .catch((error) => {
          setSubmittingArticle(false);
          console.log(error);
          setArticleError(JSON.stringify(error));
        });
    } else {
      console.log(isTemplate, "Template");
      if (
        isTemplate ||
        (titleEditable.current.innerText !== "Insert Title Here" &&
          articleContent !== "" &&
          articleImageUpload !== undefined &&
          articleCategories !== undefined)
      ) {
        const playerMentionIds = parsePlayerMentionIds(articleContent);

        CreateArticleMutation(
          titleEditable,
          articleContent,
          articleImageB64,
          published,
          isTemplate,
          articleCategories,
          setSubmittingArticle,
          setArticleError,
          articleSlate ? articleSlate.value : null,
          playerMentionIds,
          history
        );
      } else {
        setArticleError(
          "Please make sure you've entered the title and content, uploaded a cover image, and selected at least one category."
        );
        setSubmittingArticle(false);
      }
    }
  }

  let imageForDisplay = noImageUploaded;

  if (articleImageUpload !== undefined) {
    imageForDisplay = URL.createObjectURL(articleImageUpload);
  } else if (articleId !== undefined) {
    imageForDisplay = article.imageUrl;
  }

  const categorySelectOptions =
    sportsData !== undefined
      ? sportsData.articleCategories.map((category) => {
          return { value: category.id, label: category.name };
        })
      : undefined;

  const slateSelectOptions =
    slatesData !== undefined
      ? slatesData.activeSlates.map((slate) => {
          return {
            value: slate.id,
            label: `${slate.gameType.sport.name} ${
              slate.slatecompetitionSet.edges[0].node.competition.name
                ? slate.slatecompetitionSet.edges[0].node.competition.name
                : ""
            } ${slate.gameType.name} ${slate.suffix ? slate.suffix : ""} - ${
              slate.prettyStartTime
            }`,
          };
        })
      : undefined;

  function loadTemplate(e, templateTitle, templateContent) {
    e.preventDefault();

    if (
      !confirm(`Are you sure you want to load the ${templateTitle} template?`)
    ) {
      return;
    }

    setArticleContent(templateContent);
    console.log(templateContent);
  }

  return (
    <LoadingOverlay
      active={submittingArticle}
      spinner
      text="Submitting article..."
    >
      <WarningBanner active={articleError !== undefined}>
        {articleError}
      </WarningBanner>
      <ArticleEditorPageContainer>
        <ArticleEditorContainer>
          <ArticleEditorHeader>
            <ArticleEditorTitle
              id="article-editor-title"
              ref={titleEditable}
              suppressContentEditableWarning
              contentEditable
            >
              {article.title}
            </ArticleEditorTitle>
          </ArticleEditorHeader>
          <ArticleEditorWrapper>
            <Editor
              apiKey="vridl5gvx6vyaf4zjgof7p0n87hga7zejbvxhsh3e30kmtnd"
              value={articleContent !== "" ? articleContent : article.content}
              id="content-editor"
              init={{
                height: "100%",
                menubar: false,
                content_css: "./editor.css",
                file_picker_types: "image",
                browser_spellcheck: true,
                contextmenu: false,
                image_description: true,
                image_class_list: [
                  { title: "article-image", value: "article-image" },
                ],
                setup: function (editor) {
                  setEditor(editor);
                  var onAction = function (autocompleteApi, rng, value) {
                    editor.selection.setRng(rng);
                    editor.insertContent(
                      `<button class="article-player-mention" data-player-id="${value.id}">${value.name} ($${value.salary})</button> `
                    );
                    autocompleteApi.hide();
                  };

                  var getMatchedChars = function (pattern) {
                    return playersForArticleSlate.filter(function (player) {
                      return (
                        player.player.name
                          .toLowerCase()
                          .indexOf(pattern.toLowerCase()) !== -1
                      );
                    });
                  };

                  /* An autocompleter that allows you to insert special characters */
                  editor.ui.registry.addAutocompleter("playertagging", {
                    ch: "@",
                    minChars: 1,
                    columns: "auto",
                    onAction: onAction,
                    fetch: function (pattern) {
                      return new tinymce.util.Promise(function (resolve) {
                        if (!playersForArticleSlate) {
                          return;
                        }

                        var results = getMatchedChars(pattern).map(function (
                          player
                        ) {
                          return {
                            type: "cardmenuitem",
                            value: {
                              name: player.player.name,
                              salary: player.salary,
                              id: player.id,
                            },
                            label: player.player.name,
                            items: [
                              {
                                type: "cardcontainer",
                                direction: "vertical",
                                items: [
                                  {
                                    type: "cardtext",
                                    text:
                                      player.player.name +
                                      " ($" +
                                      player.salary +
                                      ")",
                                    name: "player_name",
                                  },
                                ],
                              },
                            ],
                          };
                        });
                        resolve(results);
                      });
                    },
                  });
                },
                images_upload_handler: handleArticleEmbedImageUpload,
                file_picker_callback: function (callback) {
                  console.log("Callback..");
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");

                  /*
                      Note: In modern browsers input[type="file"] is functional without
                      even adding it to the DOM, but that might not be the case in some older
                      or quirky browsers like IE, so you might want to add it to the DOM
                      just in case, and visually hide it. And do not forget do remove it
                      once you do not need it anymore.
                    */

                  input.onchange = function () {
                    const file = this.files[0];

                    const reader = new FileReader();
                    reader.onload = function () {
                      /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                      const id = file.name;
                      const blobCache =
                        // eslint-disable-next-line no-undef
                        tinymce.activeEditor.editorUpload.blobCache;
                      const base64 = reader.result.split(",")[1];
                      const blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      callback(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
                plugins: [
                  "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                  "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                  "table emoticons template paste help",
                ],
                toolbar:
                  "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | link image | print preview fullpage | " +
                  "forecolor backcolor emoticons | help",
                mobile: {
                  toolbar1:
                    "undo redo | styleselect | bold italic | forecolor backcolor | ",
                  toolbar2:
                    "bullist numlist outdent indent | link image | print preview fullpage | ",
                  toolbar3:
                    "alignleft aligncenter alignright alignjustify | emoticons help",
                },
              }}
              onEditorChange={handleEditorChange}
            />
          </ArticleEditorWrapper>
        </ArticleEditorContainer>
        <ArticleEditorSideBarContainer>
          <ArticleEditorSideBarButtons>
            <Button onClick={(e) => submitArticle(e, false)}>Save Draft</Button>
            <Button onClick={(e) => submitArticle(e, true)}>Submit</Button>
          </ArticleEditorSideBarButtons>

          <ArticleEditorSideBarImage src={imageForDisplay} />
          {articleId !== undefined ? null : (
            <ArticleEditorSideBarImageUpload
              type="file"
              label="Upload Cover Photo"
              onChange={handleImageUpload}
            />
          )}

          <ArticleEditorSideBarSportSelectLabel htmlFor="sport-select">
            Select Categories:
          </ArticleEditorSideBarSportSelectLabel>
          {articleId === undefined || articleCategories !== undefined ? (
            <Select
              id="sport-select"
              isMulti
              options={categorySelectOptions}
              onChange={handleSportChange}
              defaultValue={articleCategories}
            />
          ) : null}

          <ArticleEditorSideBarSportSelectLabel htmlFor="slate-select">
            Select Slate:
          </ArticleEditorSideBarSportSelectLabel>
          {articleId === undefined || articleSlate !== null ? (
            <Select
              id="slate-select"
              options={slateSelectOptions}
              onChange={handleSlateChange}
              defaultValue={articleSlate}
            />
          ) : null}

          {loadingSlatePlayers ? (
            <p>Loading players for tagging...</p>
          ) : articleSlate ? (
            <p>Players from {articleSlate.label} loaded for tagging.</p>
          ) : (
            <p>Select a slate to tag players.</p>
          )}

          <ArticleEditorSideBarButtons>
            <Button onClick={(e) => submitArticle(e, false, true)}>
              Save Template
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowTemplates(true);
              }}
            >
              Load Template
            </Button>
          </ArticleEditorSideBarButtons>

          {showTemplates ? (
            <div>
              {templatesData ? (
                templatesData.articleTemplates.map((template) => {
                  return (
                    <p key={template.title}>
                      {template.title}{" "}
                      <button
                        onClick={(e) =>
                          loadTemplate(e, template.title, template.content)
                        }
                      >
                        Load
                      </button>
                    </p>
                  );
                })
              ) : (
                <p>No templates to load.</p>
              )}
            </div>
          ) : null}
        </ArticleEditorSideBarContainer>
      </ArticleEditorPageContainer>
    </LoadingOverlay>
  );
}

export default ArticleEditor;
