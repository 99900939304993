import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./flex.css";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOM.createRoot(container);

import { RelayEnvironmentProvider } from "react-relay";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import environment from "./RelayEnvironment";

// eslint-disable-next-line no-undef
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const authLink = setContext((_, { headers }) => {
  return headers;
});

const httpLink = createUploadLink({
  uri: BACKEND_URL,
  headers: {
    "keep-alive": true,
  },
  credentials: "include",
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: "same-origin",
});

root.render(
  <RelayEnvironmentProvider environment={environment}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </RelayEnvironmentProvider>,
  document.getElementById("root")
);
